<template>

  <section class="mt-4 pb-5">

            <div class="section_header mt-3" v-if="success == true">
              <span>Email {{email}} {{ $t('reset.success_confirm') }}</span>
            </div>

            <div class="section_header mt-3" v-if="success == false">
              <span>{{ $t('reset.failed_confirm') }} Email {{email}}</span>
            </div>

  </section>
</template>

<script>
import axios from "axios";

export default {
  name: 'Confirm',
  props: {
    email: String,
    code: String
  },

  data: function () {
    return {
      success: null
    }
  },
  created() {

    axios.get('/api/auth/confirm?code='+ this.code + '&email=' + this.email).then(
        response => {
          if (response.status == 200) {
            this.success = true;
          } else {
            this.success = false
          }
        }
    );
  }
}
</script>
